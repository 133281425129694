import { EmailRefuseControllerService } from '@/__generated__/CommonApi';
import RefuseBodyImage from '@/assets/img/img_refuse@2x.png';
import RefuseLogoImage from '@/assets/img/img_refuse_logo@2x.png';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import { FormItem } from '@/components/Form/FormGroup';
import InputText from '@/components/Input/InputText';
import InputTextarea from '@/components/Input/InputTextarea';
import Select from '@/components/Select';
import { H3_1, H3_2 } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { emailRegex } from '@/helpers/ValidationHelper';
import BaseLayout from '@/layouts/BaseLayout';
import { ButtonFlex } from '@/page-blocks/mypage/MypageCommon';
import { usePopupStore } from '@/stores/PopupStore';
import { useLocation } from '@reach/router';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const RefuseSection = styled.div`
  background: #fff;
  padding: 40px 0;
  ${breakpoint(800)} {
    padding: 20px;
  }
`;
const RefuseWrap = styled.div`
  width: 700px;
  margin: 0 auto;
  border: 1px solid #e5e6e8;
  border-top: 5px solid #1cabe2;
  ${breakpoint(800)} {
    width: 100%;
  }
`;
const RefuseHeader = styled.div`
  padding: 20px 32px;
  ${breakpoint(640)} {
    padding: 20px 24px;
  }
`;
const RefuseLogo = styled.div`
  width: 260px;
  img {
    max-width: 100%;
  }
  ${breakpoint(640)} {
    width: 130px;
  }
`;
const RefuseBody = styled.div``;
const RefuseForm = styled.div`
  padding: 48px 32px;
  background: #e5e6e8;
  ${breakpoint(640)} {
    padding: 24px;
  }
  ${FormItem} {
    ${H3_2} {
      margin-bottom: 16px;
    }
    ${InputTextarea} {
      margin-top: 8px;
      height: 120px;
    }
  }
`;
const RefuseFoot = styled.div`
  padding: 48px 16px;
  text-align: center;
  ${ButtonFlex} {
    margin: 24px auto 0;
    padding: 0 78px;
    ${breakpoint(640)} {
      padding: 0;
    }
    ${Button} {
      width: 100%;
    }
  }
`;
export interface RefuseData {
  email: string;
  refuseReasonCode: string;
  refuseReasonDetail?: string;
  trackCode?: string;
  trackId?: string;
}
interface RefuseProps {
  queryParams: any;
}

const Refuse: FC<RefuseProps> = React.memo(() => {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const [selectVal, setSelectVal] = useState(``);
  const popupStore = usePopupStore();

  const [code, setCode] = useState(``);
  const [id, setId] = useState(``);
  const [mail, setMail] = useState(``);

  const handleChange = (val) => {
    setSelectVal(val);
  };
  useEffect(() => {
    const trkCode = queryParams.get(`trackcode`);
    if (trkCode) {
      setCode(trkCode);
    }
    const trkId = queryParams.get(`trk_id`);
    if (trkId) {
      setId(trkId);
    }
    const trkEmail = queryParams.get(`email`);
    if (trkEmail) {
      setMail(trkEmail);
    }
  }, [queryParams]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    clearErrors,
    formState: { errors },
    getValues,
    reset,
  } = useForm<RefuseData>({
    // 가변 defaultValues
    defaultValues: {
      email: ``,
      refuseReasonCode: ``,
      refuseReasonDetail: ``,
      trackCode: ``,
      trackId: ``,
    },
  });

  const onSubmit: SubmitHandler<RefuseData> = useCallback(
    async ({
      email,
      refuseReasonCode,
      refuseReasonDetail,
      trackCode,
      trackId,
      ...formData
    }) => {
      try {
        const {
          resultCode,
          resultMessage,
        } = await EmailRefuseControllerService.emailRefuseProcUsingPost({
          email,
          refuseReasonCode,
          refuseReasonDetail,
          trackCode,
          trackId,
          ...formData,
        });
        popupStore.show(resultMessage);
      } catch (e) {
        popupStore.show(
          `이메일 수신거부 접수에 실패했습니다. 잠시 후 다시 시도해주세요.`,
        );
        // console.error(e);
      }
    },
    [popupStore, reset],
  );

  return (
    <BaseLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" value={code} ref={register()} name="trackCode" />
        <input type="hidden" value={id} ref={register()} name="trackId" />
        <RefuseSection>
          <RefuseWrap>
            <RefuseHeader>
              <RefuseLogo>
                <img
                  src={RefuseLogoImage}
                  alt="유니세프 | UNICEF | for every child"
                />
              </RefuseLogo>
            </RefuseHeader>
            <RefuseBody>
              <img src={RefuseBodyImage} alt="" />
              <RefuseForm>
                <FormItem>
                  <H3_2>수신을 거부할 이메일 주소를 확인해 주세요.</H3_2>
                  <InputText
                    readOnly
                    placeholder="이메일 주소 입력"
                    name="email"
                    value={mail}
                    ref={register({
                      required: {
                        value: true,
                        message: `이메일을 입력해주세요.`,
                      },
                      pattern: {
                        value: emailRegex,
                        message: `올바른 이메일 형식이 아닙니다.`,
                      },
                      maxLength: {
                        value: 100,
                        message: `100자 이내로 입력해주세요.`,
                      },
                    })}
                  />
                  {errors.email && (
                    <ErrorMessage>{errors.email.message}</ErrorMessage>
                  )}
                </FormItem>
                <FormItem>
                  <H3_2>
                    수신거부 이유를 알려주시면 더 좋은 소식을 전할 수 있도록
                    개선하겠습니다.
                  </H3_2>
                  <Select
                    onChange={(e) => handleChange(e.target.value)}
                    ref={register({
                      required: {
                        value: true,
                        message: `수신거부 이유를 선택해주세요.`,
                      },
                    })}
                    name="refuseReasonCode"
                  >
                    <option value="" disabled>
                      수신거부 이유 선택
                    </option>
                    <option value="1">이메일을 너무 자주 받아서</option>
                    <option value="2">원하는 내용이 아니라서</option>
                    <option value="3">유니세프에 더 이상 관심이 없어서</option>
                    <option value="4">
                      수신 이메일을 더 이상 사용하지 않아서
                    </option>
                    <option value="5">기타 (직접 입력)</option>
                  </Select>
                  {errors.refuseReasonCode && (
                    <ErrorMessage>
                      {errors.refuseReasonCode.message}
                    </ErrorMessage>
                  )}
                  {selectVal === `5` && (
                    <InputTextarea
                      placeholder="수신거부 사유를 입력해 주세요."
                      name="refuseReasonDetail"
                      ref={register({
                        required: {
                          value: true,
                          message: `수신거부 사유를 입력해 주세요..`,
                        },
                      })}
                    />
                  )}
                </FormItem>
              </RefuseForm>
            </RefuseBody>
            <RefuseFoot>
              <H3_1>유니세프에서 보내는 이메일 수신을 거부하시겠습니까?</H3_1>
              <ButtonFlex>
                <li>
                  <Button type="submit">네</Button>
                </li>
                <li>
                  <Button color="grey" onClick={() => window.close()}>
                    아니오
                  </Button>
                </li>
              </ButtonFlex>
            </RefuseFoot>
          </RefuseWrap>
        </RefuseSection>
      </form>
    </BaseLayout>
  );
});
export default Refuse;
