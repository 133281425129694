import styled from 'styled-components';

const StyledTextarea = styled.textarea`
  display: block;
  width: 100%;
  height: 240px;
  padding: 16px;
  background: #fff;
  border: 1px solid #e5e6e8;
  &:focus {
    border: 1px solid #1cabe2;
  }
`;

const InputTextarea = StyledTextarea;

export default InputTextarea;
