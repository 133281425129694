import Button from '@/components/Button';
import Container from '@/components/Container';
import FormGroup from '@/components/Form/FormGroup';
import SectionHeader from '@/components/SectionHeader';
import { NotiBox, NotiItem } from '@/components/Text/TextNoti';
import { H2, H3_2 } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

export const mypageMenu = [
  {
    id: 1,
    name: `메인`,
    link: `/mypage`,
  },
  {
    id: 3,
    name: `후원내역`,
    link: `/mypage/support-history`,
  },
  {
    id: 4,
    name: `후원 및 결제정보`,
    link: `/mypage/support-info`,
  },
  {
    id: 5,
    name: `기부금 영수증`,
    link: `/mypage/my-receipt`,
  },
  {
    id: 6,
    name: `발송현황`,
    link: `/mypage/reward-list`,
  },
  {
    id: 7,
    name: `1:1문의`,
    link: `/mypage/onetoone`,
  },
  {
    id: 2,
    name: `개인정보`,
    link: `/mypage/my-info`,
  },
];

export const SectionTop = styled(SectionHeader)`
  padding-top: 96px;
  padding-bottom: 0;
  ${breakpoint(`mobile`)} {
    padding-top: 72px;
  }
  ${breakpoint(768)} {
    ${Button} {
      margin-top: 0;
    }
  }
  ${NotiBox} {
    a {
      text-decoration: underline;
      color: #1cabe2;
    }
  }
  ${breakpoint(640)} {
    ${NotiBox} {
      width: calc(100% + 40px);
      position: relative;
      left: -20px;
      border-left: none;
      border-right: none;
      margin-bottom: 70px;
    }
    ${NotiItem} {
      font-size: 14px;
    }
  }
`;
export const TitleArea = styled.div`
  margin-bottom: 24px;
  ${H2} {
    margin-bottom: 32px;
    ${breakpoint(640)} {
      margin-bottom: 16px;
    }
  }
  ${H3_2} {
    br {
      display: none;
      ${breakpoint(640)} {
        display: block;
      }
    }
  }
  /* TODO p 아래에 div가 오면 warning이 떠서 나중에 div로 리펙토링 필요 */
  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const ContainerFooter = styled(Container)``;
export const ContainerGroup = styled.div`
  ${breakpoint(640)} {
    ${NotiBox} {
      width: calc(100% + 40px);
      position: relative;
      left: -20px;
      border-left: none;
      border-right: none;
      margin-bottom: 70px;
    }
    ${NotiItem} {
      font-size: 14px;
    }
  }
  ${Container} {
    & + ${Container} {
      margin-top: 96px;
      ${breakpoint(640)} {
        margin-top: 72px;
      }
    }
    & + ${ContainerFooter} {
      margin-top: 240px;
      ${breakpoint(640)} {
        margin-top: 120px;
      }
    }
  }
`;
export const ContentsArea = styled.div<{ noBorderBottom?: boolean }>`
  padding: 32px 0;
  border-top: 2px solid #1cabe2;
  ${({ noBorderBottom }) =>
    !noBorderBottom && `border-bottom: 1px solid #b7b8ba;`}
`;
export const DropFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${breakpoint(1024)} {
    display: block;
  }
`;
export const DropFlexCell = styled.div`
  width: 100%;
  text-align: left;
  ${FormGroup} {
    width: 320px;
    ${breakpoint(640)} {
      width: 100%;
    }
  }
  ${breakpoint(640)} {
    & + div {
      margin-top: 22px;
    }
  }
`;
export const CellTitle = styled.strong`
  display: inline-block;
  width: 96px;
`;
export const ButtonFlex = styled.ul`
  display: flex;
  margin: 0 auto;
  margin-top: 96px;
  justify-content: space-between;
  & > li {
    width: 48%;
    button {
      width: 100%;
    }
  }
  ${breakpoint(640)} {
    margin-top: 72px;
  }
`;
export const PayInfoList = styled.ul``;
export const PayInfoItem = styled.li<{ type?: string }>`
  display: flex;
  strong {
    width: 192px;
  }
  & + li {
    margin-top: 24px;
  }
  ${breakpoint(640)} {
    display: block;
    position: relative;
    strong {
      display: block;
      width: 100%;
      margin-bottom: 16px;
    }
  }
  ${({ type }) => {
    if (type === `form`) {
      return `
      min-height: 56px;
      align-items: center;
          `;
    }
    return `
      `;
  }}
  ${breakpoint(800)} {
    ${({ type }) => {
      if (type === `form`) {
        return `
        padding-left: 0;
        strong {
          position: static;
          display: block;
          margin-bottom: 8px;
        }
        label {
          margin-bottom: 4px;
        }
            `;
      }
      return `
        `;
    }}
  }
  ${breakpoint(640)} {
    ${({ type }) => {
      if (type === `form`) {
        return `
        min-height: auto;
            `;
      }
      return `
        `;
    }}
  }
`;
